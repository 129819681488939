<template>
    <div class="flex align-items-center py-5 px-3">
        <i class="pi pi-fw pi-check mr-2 text-2xl" />
        <p class="m-0 text-lg">Confirmation Component Content via Child Route</p>
    </div>
</template>

<script>
export default {

}
</script>